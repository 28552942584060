export default {
  home: {
    minute: 'minute',
    price_info: '{mianfei} minutes free, maximum per day  {currencyName}{fengding}, deposit {currencyName}{yajin},  free rental deposit',
    feature_1: 'Special design for wireless charging',
    feature_2: 'National universal borrowing and repayment',
    feature_3: 'Four charging modes, suitable for all mobile phones',
    submit: 'Recharge',
    borrow: 'Borrow',
    inputcodetitle: 'Please enter the device number on the cabinet',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    cancel: 'Cancel',
    confirm: 'Confirm',
    msg_fail_title: 'Error',
    msg_fail_content: 'Rental failed, please try again',
    shoptime: 'Business Time',
    supportrental: 'Support rental',
    supportreturn: 'Support return',
    useragreen: 'Click to agree to the "Delegated Debit Authorization Form", "User Agreement", "Top-up Agreement',
    privacyagreen: 'Click to agree to the Privacy Agreement',
    offline: 'The device {devicecode} is offline, please contact the staff present',
    permissionfail: 'Permission denied.',
    unbound: 'Mix QR code {devicecode} is not bound to the device',
    nobattery: 'No battery available in device {devicecode}'
  },
  loginborrow: {
    powerbanktitle: 'Sign in to unlock your power bank',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    msg_fail_support: 'The system is not supported',
    msg_fail_confirm: 'Confirm',
    msg_fail_retry: 'Retry',
    msg_fail_cancel: 'Cancel',
    msg_fail_order: 'Failed to create lease order',
    msg_fail_paymod: 'Failed to obtain payment method',
    msg_fail_login: 'Login failed',
    Apple: 'Apple',
    otheropt: 'Other options',
    selectmethod: 'Select your payment method',
    paymentdetail: 'Payment method details',
    couponcode: 'Coupon code',
    rent: 'Confirm Payment',
    pricing: 'See pricing',
    phone: 'Phone',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Sign in with Facebook',
    logingg: 'Sign in with Google',
    captcha_code: 'Code',
    phonenumber: 'Enter your phone number',
    msg_fail_code: 'Please enter the verification code',
    msg_fail_phone: 'Please enter your phone number',
    logout: 'Exit account',
    savenow: 'Save Now',
    delete: 'Delete',
    addcard: 'Enter card details',
    msg_fail_content: 'Rental failed, please try again',
    title: 'Please confirm authorisation',
    policy: 'Policy.',
    term: ' Terms and Conditions',
    and: 'and',
    privacy: 'Privacy Policy.',
    tips: 'By continuing you agree to our ',
    cancel: 'CANCEL',
    agree: 'AGREE'
  },
  preauth: {
    preauthortitle: 'About the pre-authorisation',
    tip1: 'When starting a rental, a pre-authorisation is placed on your payment method. This is just to ensure there are sufficient funds available to pay for your rental.',
    tip2: 'Your bank may send you a notification but it is not a charge: only the price of your rental will be charged.',
    tip3: 'Depending on your bank, it can take a few hours up to a few days for the amount to be adjusted on your statements, this is normal.'
  },
  rentcost: {
    rentcosttitle: 'How much does the rental cost?',
    tip1: 'If you do not return the power bank within 48 hours you will be charged a non-return fee of {currency} {yajin} .',
    tip2: 'Duration is rounded to the nearest half hour (30 or 60 minutes).',
    tip3: 'One day rental is 24 hours from the time the power bank is hired.',
    tip3_1: 'A pre-authorisation fee of {currency}{currencyName} {cyajin} will be placed on your payment method. This is to ensure there are sufficient funds available to pay for your rental.',
    tip3_2: 'Your bank may send you a notification but it is not a charge; only the price of your rental will be charged.',
    tip3_3: 'Depending on your bank, it can take a few hours, up to a few days for the amount to be adjusted on your statements.',
    tip4: 'Some venues might offer some free hours or discounts. Enjoy!',
    Days: 'Days',
    Hours: 'Hours',
    Minutes: 'Minutes'
  },
  logincode: {
    login: 'Login',
    retry: 'Resend',
    title: 'Verification code',
    subtitle: 'Please enter the verification code we sent to your phone number'
  },
  agreenconfirm: {
    title: 'Please confirm authorisation',
    policy: 'Policy.',
    term: "возьми заряд Terms & Conditions",
    and: 'and',
    privacy: 'Privacy Policy.',
    tips: 'l agree to ',
    cancel: 'CANCEL',
    agree: 'AGREE'
  },
  users: {
    username: 'User Name',
    mobile: 'Mobile',
    login: 'Login',
    orders: 'Rental orders',
    wallet: 'My Wallet',
    joinus: 'Cooperation to join',
    langsetting: 'Language settings'
  },
  login: {
    phonenumber: 'Phone Number',
    verificationcode: 'Verification Code',
    Rentpowerbank: 'Rent a power bank',
    Rentpowerbanktip: 'Enter your cell phone number to get started',
    phone_number: 'Please enter an {0}-digit mobile number ',
    captcha_code: 'Code',
    send_code: 'Send Code',
    login: 'Login',
    agree: 'By clicking Login, you agree to ',
    readme: `User Agreement`,
    msg_fail_login: 'Login failed',
    msg_success_login: 'Login successful',
    msg_success_login_code: 'Successfully received, please download the APP to log in to use',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    no_sms: 'Did not receive text message',
    retry: 'Resend',
    receive: 'Apply',
    invite_pwd: 'Invitation code',
    vip_agreen_tip: 'By continuing, you are indicating that you accept our',
    vip_agreen_service: 'Terms of Service',
    vip_agreen_and: 'and',
    vip_agreen_policy: 'Privacy Policy'
  },
  confirm: {
    title: 'Payment Confirmation',
    pay_label: 'Amount',
    addcard: 'Add Wallet Card',
    paynow: "Immediate payment",
    price_tips: 'Click to confirm payment, indicating that you have read and agreed to the ',
    price_btn: '《Value-added Agreement》',
    pay_type: 'Please select your preferred payment method',
    submit: 'Confirm lease',
    charge: 'Confirm recharge',
    msg_fail_title: 'Warning',
    msg_fail_order: 'Failed to create lease order',
    msg_fail_confirm: 'Confirm',
    msg_fail_retry: 'Retry',
    msg_fail_cancel: 'Cancel'
  },
  join: {
    title: 'Partnership',
    call: 'Call',
    tips: 'please provide your information, we will contact you shortly!',
    type1: 'Installation',
    type2: 'Advertising',
    type3: 'Other',
    shop_name: 'Merchant ’s name',
    contact_person: 'Contact Person',
    phone: 'Contact Number',
    submit: 'Submission',
    noempty: 'Cannot be empty'
  },
  mine: {
    title: 'User Profile',
    wallet: 'My Wallet',
    balance: 'My Balance',
    mywalletcard: 'My Card',
    addcard: 'Add Wallet Card',
    deposit: 'Deposit',
    wallet_in: 'Recharge',
    wallet_out: 'Withdraw',
    wallet_log: 'Wallet Log',
    order: 'Order',
    login_tips: 'Please Login',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    help: 'Help',
    setting: 'Setting',
    logout: 'Logout',
    unpaid: 'Unpaid',
    using: 'In Use'
  },
  msg: {
    nopaymode: 'Payment method acquisition failed',
    unlogin: 'You haven\'t logged in yet, please login',
    msg_fail_title: 'Warning',
    deletecard: 'Are you sure to delete this card?',
    loading: 'Loading...',
    title: 'Tips',
    success: 'Successful operation',
    error: 'Unknown error,please try again later.',
    userinfo_fail: 'User information invalid, please login again',
    roleerror: 'Insufficient permissions, please consult the administrator',
    neterror: 'Network connection failed. Please try again later',
    confirm: 'Confirm',
    retry: 'Retry',
    cancel: 'Cancel',
    msg_logout: 'Are you sure to exit the account?',
    vieworder: 'View order',
    addorselectcard: 'Please add or select a card package to continue',
    success_payment: 'Payment completed',
    fail_payment: 'Payment failure',
    keyword: 'keyword',
    unpaid_order: 'There are unpaid orders',
    go_pay: 'Go pay',
    no_more: 'no more',
    noshop: 'No merchants',
    msg_fail_code: 'Please enter the verification code',
    msg_fail_phone: 'Please enter your phone number',
    exceedorder: 'You already have an order under lease, please return it before continuing'
  },
  result: {
    title: 'Rental confirmed',
    tips: 'To view orders and other возьми заряд locations, head to the home page to see more.',
    download: 'DOWNLOAD',
    vieworder: 'View Order',
    msg_result_loading: 'The payment result is being confirmed...',
    msg_tips_loading: 'Portable battery is popping up, please wait patiently...',
    haveorder: 'Official order has been generated'
  },
  result_msg: {
    loading: 'Loading...',
    refresh: 'Refresh'
  },
  order: {
    title: 'Lease Order',
    in_use: 'In use',
    ended: 'Ended',
    msg_error: 'Request failed',
    no_data: 'No Data'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Venue rented',
    time_rented: 'Time rented',
    venue_returned: 'Venue returned',
    time_returned: 'Time returned',
    charge_standard: 'Charge standard',
    rent_occurred: 'Rent occurred',
    returned: 'Returned',
    unreturned: 'Unreturned',
    type_0: 'Not Completed',
    type_1: 'Renting',
    type_2: 'Canceled',
    type_3: 'Returned',
    type_4: 'Fault occurred',
    type_5: 'Overtime',
    min: 'Min',
    unpaid: 'Unpaid',
    payment: 'Payment'
  },
  setting: {
    title: 'Setting',
    lang: 'Language'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Add Value',
    price_label: 'Please select other recharge amount',
    price_placeholder: 'please enter the reload amount',
    balance: 'Balance',
    chargemoney: 'Please select the amount of recharge',
    submit: 'Submit',
    loading: 'Loading ...',
    msg_title: 'Reminder'
  },
  wallet_out: {
    title: 'Withdrawal',
    price_label: 'Please enter the withdrawal amount',
    price_placeholder: 'Please enter the withdrawal amount',
    balance: 'Current balance',
    balance_all: 'Withdraw all',
    deposit: 'Deposit',
    deposit_tips: 'There is an lease order. The deposit of {pcurrencyName}{pyajin} is non-refundable',
    submit: 'Submit',
    apply: 'Apply',
    confirm: 'Confirm',
    loading: 'Loading...',
    msg_fail_price: 'The withdrawal amount is incorrect',
    msg_fail_balance: 'The withdrawal amount cannot be greater than the current balance',
    msg_title: 'Error',
    msg_success_content: 'Apply Refund successful',
    tips_title: 'Explanation',
    btn: 'Submit',
    error_tips: 'Please enter complete information'
  },
  map: {
    在线: "Online",
    离线: "Offline",
    title: "Nearby Station",
    result_msg: "No station was found nearby",
    msg_title: "error",
    no_support: "Sorry! Your browser does not support location services",
    my_position: "My Location",
    distance: "Distance:",
    shop_time: "Hours",
    free: "Available for Rental",
    return: "Available empty slots",
    shop_title: "Venue details",
    address: "Address",
    go_here: "Go here"
  }
}
