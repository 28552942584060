export default {
  home: {
    minute: 'минута',
    price_info: '{mianfei} минут бесплатно, максимум в день {currencyName}{fengding}, залог {currencyName}{yajin}, бесплатная аренда без залога',
    feature_1: 'Особый дизайн для беспроводной зарядки',
    feature_2: 'Национальное универсальное заимствование и возврат',
    feature_3: 'Четыре режима зарядки, подходящие для всех мобильных телефонов',
    submit: 'Пополнить',
    borrow: 'Арендовать',
    inputcodetitle: 'Пожалуйста, введите номер устройства на шкафчике',
    msg_rentcount: 'У вас сейчас {zujieNum} незакрытых заказов',
    cancel: 'Отменить',
    confirm: 'Подтвердить',
    msg_fail_title: 'Ошибка',
    msg_fail_content: 'Аренда не удалась, пожалуйста, попробуйте еще раз',
    shoptime: 'Время работы',
    supportrental: 'Поддержка аренды',
    supportreturn: 'Поддержка возврата',
    useragreen: 'Нажмите, чтобы согласиться с "Формой авторизации на дебетование", "Пользовательским соглашением", "Соглашением о пополнении счета"',
    privacyagreen: 'Нажмите, чтобы согласиться с Политикой конфиденциальности',
    offline: 'Устройство {devicecode} оффлайн, пожалуйста, свяжитесь с персоналом',
    permissionfail: 'Доступ запрещен.',
    unbound: 'Код Mix QR {devicecode} не привязан к устройству',
    nobattery: 'Нет доступной батареи в устройстве {devicecode}'
  },
  loginborrow: {
    powerbanktitle: 'Войдите, чтобы разблокировать ваш пауэрбанк',
    msg_fail_title: 'Ошибка',
    msg_fail_user: 'Ошибка получения информации о пользователе. Хотите попробовать снова?',
    msg_rentcount: 'У вас сейчас {zujieNum} незакрытых заказов',
    msg_fail_support: 'Система не поддерживается',
    msg_fail_confirm: 'Подтвердить',
    msg_fail_retry: 'Повторить',
    msg_fail_cancel: 'Отменить',
    msg_fail_order: 'Не удалось создать заказ на аренду',
    msg_fail_paymod: 'Не удалось получить способ оплаты',
    msg_fail_login: 'Ошибка входа',
    Apple: 'Apple',
    otheropt: 'Другие варианты',
    selectmethod: 'Выберите способ оплаты',
    paymentdetail: 'Детали способа оплаты',
    couponcode: 'Код купона',
    rent: 'Подтвердить оплату',
    pricing: 'Смотреть цены',
    phone: 'Телефон',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Войти через Facebook',
    logingg: 'Войти через Google',
    captcha_code: 'Код',
    phonenumber: 'Введите ваш номер телефона',
    msg_fail_code: 'Пожалуйста, введите код подтверждения',
    msg_fail_phone: 'Пожалуйста, введите ваш номер телефона',
    logout: 'Выйти из аккаунта',
    savenow: 'Сохранить сейчас',
    delete: 'Удалить',
    addcard: 'Введите данные карты',
    msg_fail_content: 'Аренда не удалась, пожалуйста, попробуйте еще раз',
    title: 'Пожалуйста, подтвердите авторизацию',
    policy: 'Политика.',
    term: 'Условия и положения',
    and: 'и',
    privacy: 'Политика конфиденциальности.',
    tips: 'Продолжая, вы соглашаетесь с нашими ',
    cancel: 'ОТМЕНИТЬ',
    agree: 'СОГЛАСЕН'
  },
  preauth: {
    preauthortitle: 'О предварительной авторизации',
    tip1: 'При начале аренды на вашем способе оплаты будет выполнена предварительная авторизация. Это необходимо, чтобы убедиться, что на вашем счете достаточно средств для оплаты аренды.',
    tip2: 'Ваш банк может отправить вам уведомление, но это не является списанием средств: будет списана только стоимость аренды.',
    tip3: 'В зависимости от вашего банка, это может занять от нескольких часов до нескольких дней для корректировки суммы в ваших выписках, это нормально.'
  },
  rentcost: {
    rentcosttitle: 'Сколько стоит аренда?',
    tip1: 'Если вы не вернете пауэрбанк в течение 48 часов, с вас будет взиматься плата за невозврат {currency} {yajin}.',
    tip2: 'Продолжительность округляется до ближайшего получаса (30 или 60 минут).',
    tip3: 'Суточная аренда составляет 24 часа с момента аренды пауэрбанка.',
    tip3_1: 'На вашем способе оплаты будет выполнена предварительная авторизация в размере {currency}{currencyName} {cyajin}. Это необходимо, чтобы убедиться, что на вашем счете достаточно средств для оплаты аренды.',
    tip3_2: 'Ваш банк может отправить вам уведомление, но это не является списанием средств; будет списана только стоимость аренды.',
    tip3_3: 'В зависимости от вашего банка, это может занять от нескольких часов до нескольких дней для корректировки суммы в ваших выписках.',
    tip4: 'Некоторые заведения могут предлагать бесплатные часы или скидки. Наслаждайтесь!',
    Days: 'Дни',
    Hours: 'Часы',
    Minutes: 'Минуты'
  },
  logincode: {
    login: 'Вход',
    retry: 'Отправить снова',
    title: 'Код подтверждения',
    subtitle: 'Пожалуйста, введите код подтверждения, который мы отправили на ваш номер телефона'
  },
  agreenconfirm: {
    title: 'Пожалуйста, подтвердите авторизацию',
    policy: 'Политика.',
    term: "Условия и положения возьми заряд",
    and: 'и',
    privacy: 'Политика конфиденциальности.',
    tips: 'Я согласен с ',
    cancel: 'ОТМЕНИТЬ',
    agree: 'СОГЛАСЕН'
  },
  users: {
    username: 'Имя пользователя',
    mobile: 'Мобильный',
    login: 'Вход',
    orders: 'Заказы на аренду',
    wallet: 'Мой Кошелек',
    joinus: 'Сотрудничество',
    langsetting: 'Настройки языка'
  },
  login: {
    phonenumber: 'Номер телефона',
    verificationcode: 'Код подтверждения',
    Rentpowerbank: 'Арендовать пауэрбанк',
    Rentpowerbanktip: 'Введите ваш номер телефона, чтобы начать',
    phone_number: 'Пожалуйста, введите {0}-значный номер мобильного телефона ',
    captcha_code: 'Код',
    send_code: 'Отправить код',
    login: 'Вход',
    agree: 'Нажимая Войти, вы соглашаетесь с ',
    readme: `Пользовательское соглашение`,
    msg_fail_login: 'Ошибка входа',
    msg_success_login: 'Вход успешен',
    msg_success_login_code: 'Успешно получено, пожалуйста, скачайте приложение для входа',
    msg_fail_user: 'Ошибка получения информации о пользователе. Хотите попробовать снова?',
    no_sms: 'Не получили текстовое сообщение',
    retry: 'Отправить снова',
    receive: 'Получить',
    invite_pwd: 'Код приглашения',
    vip_agreen_tip: 'Продолжая, вы подтверждаете, что принимаете наши',
    vip_agreen_service: 'Условия использования',
    vip_agreen_and: 'и',
    vip_agreen_policy: 'Политика конфиденциальности'
  },
  confirm: {
    title: 'Подтверждение оплаты',
    pay_label: 'Сумма',
    addcard: 'Добавить карту кошелька',
    paynow: "Оплатить сейчас",
    price_tips: 'Нажимая Подтвердить оплату, вы подтверждаете, что прочитали и согласились с ',
    price_btn: '《Дополнительное соглашение》',
    pay_type: 'Пожалуйста, выберите предпочитаемый способ оплаты',
    submit: 'Подтвердить аренду',
    charge: 'Подтвердить пополнение',
    msg_fail_title: 'Предупреждение',
    msg_fail_order: 'Не удалось создать заказ на аренду',
    msg_fail_confirm: 'Подтвердить',
    msg_fail_retry: 'Повторить',
    msg_fail_cancel: 'Отменить'
  },
  join: {
    title: 'Партнерство',
    call: 'Звонок',
    tips: 'пожалуйста, предоставьте вашу информацию, мы свяжемся с вами в ближайшее время!',
    type1: 'Установка',
    type2: 'Реклама',
    type3: 'Другое',
    shop_name: 'Название магазина',
    contact_person: 'Контактное лицо',
    phone: 'Контактный номер',
    submit: 'Отправить',
    noempty: 'Не может быть пустым'
  },
  mine: {
    title: 'Профиль пользователя',
    wallet: 'Мой Кошелек',
    balance: 'Мой Баланс',
    mywalletcard: 'Моя Карта',
    addcard: 'Добавить карту кошелька',
    deposit: 'Депозит',
    wallet_in: 'Пополнение',
    wallet_out: 'Вывод',
    wallet_log: 'История кошелька',
    order: 'Заказ',
    login_tips: 'Пожалуйста, войдите',
    msg_fail_title: 'Ошибка',
    msg_fail_user: 'Ошибка получения информации о пользователе. Хотите попробовать снова?',
    help: 'Помощь',
    setting: 'Настройки',
    logout: 'Выйти',
    unpaid: 'Неоплачено',
    using: 'Используется'
  },
  msg: {
    nopaymode: 'Не удалось получить способ оплаты',
    unlogin: 'Вы еще не вошли, пожалуйста, войдите',
    msg_fail_title: 'Предупреждение',
    deletecard: 'Вы уверены, что хотите удалить эту карту?',
    loading: 'Загрузка...',
    title: 'Советы',
    success: 'Операция успешна',
    error: 'Неизвестная ошибка, попробуйте позже.',
    userinfo_fail: 'Недействительная информация о пользователе, пожалуйста, войдите снова',
    roleerror: 'Недостаточно прав, пожалуйста, обратитесь к администратору',
    neterror: 'Не удалось подключиться к сети. Попробуйте позже',
    confirm: 'Подтвердить',
    retry: 'Повторить',
    cancel: 'Отменить',
    msg_logout: 'Вы уверены, что хотите выйти из аккаунта?',
    vieworder: 'Посмотреть заказ',
    addorselectcard: 'Пожалуйста, добавьте или выберите карту кошелька для продолжения',
    success_payment: 'Оплата завершена',
    fail_payment: 'Ошибка оплаты',
    keyword: 'ключевое слово',
    unpaid_order: 'Есть неоплаченные заказы',
    go_pay: 'Перейти к оплате',
    no_more: 'больше нет',
    noshop: 'Нет магазинов',
    msg_fail_code: 'Пожалуйста, введите код подтверждения',
    msg_fail_phone: 'Пожалуйста, введите ваш номер телефона',
    exceedorder: 'У вас уже есть заказ на аренду, пожалуйста, верните его перед продолжением'
  },
  result: {
    title: 'Аренда подтверждена',
    tips: 'Чтобы посмотреть заказы и другие местоположения возьми заряд, перейдите на главную страницу для просмотра.',
    download: 'СКАЧАТЬ',
    vieworder: 'Посмотреть заказ',
    msg_result_loading: 'Подтверждение результата оплаты...',
    msg_tips_loading: 'Портативная батарея извлекается, пожалуйста, подождите...',
    haveorder: 'Официальный заказ создан'
  },
  result_msg: {
    loading: 'Загрузка...',
    refresh: 'Обновить'
  },
  order: {
    title: 'Заказ на аренду',
    in_use: 'Используется',
    ended: 'Завершен',
    msg_error: 'Запрос не удался',
    no_data: 'Нет данных'
  },
  order_item: {
    order_number: '№',
    venue_rented: 'Место аренды',
    time_rented: 'Время аренды',
    venue_returned: 'Место возврата',
    time_returned: 'Время возврата',
    charge_standard: 'Стандарт зарядки',
    rent_occurred: 'Произошла аренда',
    returned: 'Возвращено',
    unreturned: 'Не возвращено',
    type_0: 'Не завершено',
    type_1: 'Арендуется',
    type_2: 'Отменено',
    type_3: 'Возвращено',
    type_4: 'Произошла ошибка',
    type_5: 'Превышено время',
    min: 'Мин',
    unpaid: 'Неоплачено',
    payment: 'Оплата'
  },
  setting: {
    title: 'Настройки',
    lang: 'Язык'
  },
  help: {
    title: 'Часто задаваемые вопросы'
  },
  wallet_in: {
    title: 'Пополнение',
    price_label: 'Пожалуйста, выберите сумму пополнения',
    price_placeholder: 'пожалуйста, введите сумму пополнения',
    balance: 'Баланс',
    chargemoney: 'Пожалуйста, выберите сумму пополнения',
    submit: 'Отправить',
    loading: 'Загрузка...',
    msg_title: 'Напоминание'
  },
  wallet_out: {
    title: 'Вывод',
    price_label: 'Пожалуйста, введите сумму вывода',
    price_placeholder: 'Пожалуйста, введите сумму вывода',
    balance: 'Текущий баланс',
    balance_all: 'Вывести все',
    deposit: 'Депозит',
    deposit_tips: 'Есть заказ на аренду. Залог в размере {pcurrencyName}{pyajin} не подлежит возврату',
    submit: 'Отправить',
    apply: 'Применить',
    confirm: 'Подтвердить',
    loading: 'Загрузка...',
    msg_fail_price: 'Некорректная сумма вывода',
    msg_fail_balance: 'Сумма вывода не может превышать текущий баланс',
    msg_title: 'Ошибка',
    msg_success_content: 'Запрос на возврат успешно отправлен',
    tips_title: 'Объяснение',
    btn: 'Отправить',
    error_tips: 'Пожалуйста, введите полную информацию'
  },
  map: {
    在线: "Онлайн",
    离线: "Оффлайн",
    title: "Ближайшая станция",
    result_msg: "Рядом не найдено ни одной станции",
    msg_title: "Ошибка",
    no_support: "Извините! Ваш браузер не поддерживает службы определения местоположения",
    my_position: "Мое местоположение",
    distance: "Расстояние:",
    shop_time: "Часы работы",
    free: "Доступно для аренды",
    return: "Доступные пустые слоты",
    shop_title: "Детали местоположения",
    address: "Адрес",
    go_here: "Перейти сюда"
  }
}
